<template>
  <div>
    <div v-if="loadpage">
      <PageLoader></PageLoader>
    </div>
    <div v-else class="holder">
      <div class="row">
        <div class="col-md-9">
          <!-- Notification area starts -->
          <div
            class="approval-request-notification"
            v-if="user.overdraft_user_type === 'user' && showPaymentRes"
          >
            <!-- <p>{{ paymentRequestDetails.request.length }}</p> -->
            <div class="approval-header-notification">
              <h6 v-if="paymentRequestDetails.request">
                Approval request from
                {{ paymentRequestDetails.request[0].vendor.firstname }}
                {{ paymentRequestDetails.request[0].vendor.lastname }}
              </h6>
              <!-- <button class="btn close-notification">X</button> -->
            </div>
            <!-- <small>3:59 seconds</small> -->
            <button @click="openPaymentApproval()" class="btn view-request">
              View Request
            </button>
          </div>
          <div
            class="approval-request-notification"
            v-if="user.overdraft_user_type === 'user' && showCardDetails"
          >
            <div class="approval-header-notification" v-if="cardDetails2">
              <!-- <p>{{ cardDetails2.user.length }}</p> -->
              <h6 v-if="cardDetails2">
                Card upgrade request from
                {{ cardDetails2.user[0].requester.firstname }}
                {{ cardDetails2.user[0].requester.lastname }}
              </h6>
              <!-- <button class="btn close-notification">X</button> -->
            </div>
            <!-- <small>3:59 seconds</small> -->
            <button
              class="btn view-request"
              data-toggle="modal"
              data-target="#cardupgradeapproval"
            >
              View Request
            </button>
          </div>
          <!-- Notification area starts -->

          <h1 v-if="user" class="welcome-back-text">
            Hi, {{ user.firstname }}
          </h1>

          <div class="regupgrade-btn">
            <!-- AGENT PART DONT REMOVE -->
            <button
              v-if="
                user.overdraft_user_type === 'vendor' ||
                  user.overdraft_user_type === 'agent'
              "
              class="btn"
              @click="openRegistrationModal()"
            >
              Register New User
            </button>
            <button v-if="cardrequests.length > 0" class="btn">
              <router-link
                style="color: inherit; text-decoration: none"
                to="/dashboard/cardrequests"
                >Card requests ({{ cardrequests.length }})</router-link
              >
            </button>
            <button
              v-if="
                user.overdraft_user_type === 'user' &&
                  user.overdraft_pin == null
              "
              style="background: #E6F2FF; color: #555c74"
              class="btn mr-2"
              @click="openPinModal()"
            >
              Transaction pin
            </button>

            <!-- VENDOR SIDE -->
            <!-- request-payment-btn -->
            <button
              v-if="user.overdraft_user_type === 'vendor'"
              class="btn requestcard-btn"
              data-toggle="modal"
              data-target="#requestpayment"
            >
              <!-- <img
                style="margin-right: 5px; margin-bottom: 2px;"
                src="../../assets/icons/card-receive.svg"
                alt="payments"
              /> -->
              Request Payment
            </button>
            <!-- &&
                  userOverdraftCard == null -->
            <button
              v-if="
                user.overdraft_user_type === 'user' && userOverdraftCard == null
              "
              class="btn requestcard-btn"
              data-toggle="modal"
              data-target="#requestcard"
            >
              Request Card
            </button>
            <!-- <button class="btn" @click="openRegistrationModal()">
              <img src="../../assets/icons/users-gray.svg" alt="user" />
              Register New User
            </button> -->
          </div>

          <!-- Card data starts -->
          <div class="card-holder">
            <div
              class="row mobile-card"
              style="flex-wrap: nowrap; overflow-x: scroll;"
            >
              <div
                class="col-md-4 col-sm-4"
                v-if="user.overdraft_user_type === 'user'"
              >
                <div class="top-card first-card" style="background: #E6F2FF;">
                  <h6>Safe Balance</h6>
                  <h5>₦{{ Number(safebalance).toLocaleString() }}</h5>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4"
                v-if="
                  user.overdraft_user_type === 'user' &&
                    userOverdraftCard != null
                "
              >
                <div
                  class="top-card first-card"
                  v-if="userOverdraftCard != null"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h6>Ovadraft limit</h6>
                    <h4 class="cardtypecard" v-if="userOverdraftCard">
                      {{ userOverdraftCard.cardtype }}
                    </h4>
                  </div>
                  <h5 v-if="userOverdraftCard.deducted_transaction_limit">
                    ₦{{
                      Number(
                        userOverdraftCard.deducted_transaction_limit
                      ).toLocaleString()
                    }}
                  </h5>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4"
                v-if="user.overdraft_user_type === 'agent'"
              >
                <div class="top-card first-card">
                  <h6>Safe Balance</h6>
                  <h5>₦{{ Number(safebalance).toLocaleString() }}</h5>
                  <p>
                    Total Commission:
                    <span
                      >₦{{
                        Number(agentCount.total_commision).toLocaleString()
                      }}</span
                    >
                  </p>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4"
                v-if="user.overdraft_user_type === 'vendor'"
              >
                <div class="top-card third-card">
                  <h6>Safe balance</h6>
                  <h5>{{ Number(safebalance).toLocaleString() }}</h5>
                  <p>
                    Received payments:
                    <span>{{ vendorCount.received_payments }}</span>
                  </p>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4"
                v-if="user.overdraft_user_type === 'vendor'"
              >
                <div class="top-card third-card" style="background: #F2F2F2;">
                  <h6>Pending activations</h6>
                  <div
                    class="d-flex justify-content-between align-content-center"
                  >
                    <h5>{{ vendorCount.pending_applications }}</h5>
                    <router-link to="/dashboard/ovadraft-requests">
                      <button class="btn view-btn">
                        View
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4"
                v-if="user.overdraft_user_type === 'vendor'"
              >
                <div class="top-card second-card">
                  <h6>Successfully registered users</h6>
                  <!-- <h5>{{ vendorCount.registered_users }}</h5> -->
                  <div
                    class="d-flex justify-content-between align-content-center"
                  >
                    <h5>{{ vendorCount.registered_users }}</h5>
                    <router-link to="/dashboard/ovadraft-requests">
                      <button class="btn view-btn">
                        View
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4"
                v-if="user.overdraft_user_type === 'agent'"
              >
                <div class="top-card second-card">
                  <h6>Successfully registered users</h6>
                  <!-- <h5>{{ agentCount.registered_users }}</h5> -->
                  <div
                    class="d-flex justify-content-between align-content-center"
                  >
                    <h5>{{ agentCount.registered_users }}</h5>
                    <router-link to="/dashboard/ovadraft-requests">
                      <button class="btn view-btn">
                        View
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4"
                v-if="user.overdraft_user_type === 'agent'"
              >
                <div class="top-card third-card">
                  <h6>Pending activations</h6>
                  <div
                    class="d-flex justify-content-between align-content-center"
                  >
                    <h5>{{ agentCount.pending_applications }}</h5>
                    <router-link to="/dashboard/ovadraft-requests">
                      <button class="btn view-btn">
                        View
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card data ends -->

          <div v-if="notifications.length > 0" class="recent-activity-holder">
            <h6 class="recent-activity-text">Recent Activities</h6>
            <div>
              <div
                class="recent-holder"
                v-for="(item, index) in notifications.slice(0, 2)"
                :key="index"
              >
                <div class="d-flex">
                  <div class="rooundedCircleBlue" v-if="index % 2 === 0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M1.33301 5.66602H8.99967"
                        stroke="white"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 11H5.33333"
                        stroke="white"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 11H9.66667"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.6663 7.35398V10.7407C14.6663 13.0807 14.073 13.6673 11.7063 13.6673H4.29301C1.92634 13.6673 1.33301 13.0807 1.33301 10.7407V5.26065C1.33301 2.92065 1.92634 2.33398 4.29301 2.33398H8.99967"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11 4.00065L12 5.00065L14.6667 2.33398"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="rooundedCirclePink" v-if="index % 2 !== 0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M12.8673 5.28097V8.71431C12.8673 10.7676 11.694 11.6476 9.93396 11.6476H4.07395C3.77395 11.6476 3.48729 11.621 3.22062 11.561C3.05396 11.5343 2.89396 11.4877 2.74729 11.4343C1.74729 11.061 1.14062 10.1943 1.14062 8.71431V5.28097C1.14062 3.22763 2.31395 2.34766 4.07395 2.34766H9.93396C11.4273 2.34766 12.5006 2.98099 12.7873 4.42765C12.834 4.69432 12.8673 4.96763 12.8673 5.28097Z"
                        stroke="white"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.8654 7.28105V10.7144C14.8654 12.7677 13.6921 13.6477 11.9321 13.6477H6.07208C5.57875 13.6477 5.13209 13.5811 4.74542 13.4344C3.95209 13.1411 3.41208 12.5344 3.21875 11.5611C3.48542 11.6211 3.77208 11.6477 4.07208 11.6477H9.93209C11.6921 11.6477 12.8654 10.7677 12.8654 8.7144V5.28105C12.8654 4.96771 12.8388 4.68773 12.7854 4.42773C14.0521 4.6944 14.8654 5.58771 14.8654 7.28105Z"
                        stroke="white"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.99927 8.76025C7.97129 8.76025 8.75928 7.97227 8.75928 7.00024C8.75928 6.02822 7.97129 5.24023 6.99927 5.24023C6.02725 5.24023 5.23926 6.02822 5.23926 7.00024C5.23926 7.97227 6.02725 8.76025 6.99927 8.76025Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.1875 5.53516V8.46851"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.8125 5.53516V8.46851"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="activity-content">
                    <div>
                      <h6>{{ item.description }}</h6>
                      <h6>{{ item.created_at | moment2 }}</h6>
                    </div>
                    <!-- <p>₦100,000</p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="view-more">
              <router-link to="/dashboard/ovadraft-activities">
                <p>View More Activities</p>
              </router-link>
            </div>
          </div>

          <!-- transactions history and details starts -->
          <!-- v-if="
              user.overdraft_user_type === 'vendor' ||
                user.overdraft_user_type === 'agent'
            " -->
          <div
            class="transactions-holder"
            v-if="pendingApproval.length > 0 || transactionHistory.length > 0"
          >
            <h6 class="transaction-text">Transactions</h6>
            <div class="tab-holding">
              <div class="tab-buttons">
                <button
                  @click="activeTransactionTab = 1"
                  class="history-btn btn"
                  :class="[activeTransactionTab === 1 ? 'active-tab-btn' : '']"
                >
                  History
                </button>
                <button
                  v-if="
                    user.overdraft_user_type === 'vendor' ||
                      user.overdraft_user_type === 'agent'
                  "
                  @click="activeTransactionTab = 2"
                  class="pending-approval-btn btn"
                  :class="[activeTransactionTab === 2 ? 'active-tab-btn' : '']"
                >
                  Pending approval
                </button>
              </div>

              <div class="transactions-body">
                <div
                  class="transaction-items"
                  v-if="activeTransactionTab === 1"
                >
                  <div
                    class="item-holder-t"
                    v-for="(item, index) in transactionHistory"
                    :key="index"
                  >
                    <div>
                      <!-- v-if="index % 2 === 0" -->
                      <img
                        src="../../assets/icons/icon-bills.svg"
                        alt="payment"
                      />
                      <!-- <img
                        v-if="index % 2 !== 0"
                        src="../../assets/icons/icon-payment-check.svg"
                        alt="payment"
                      /> -->
                      <div>
                        <h4>{{ item.description }}</h4>
                        <h5>{{ item.created_at | moment }}</h5>
                      </div>
                    </div>
                    <div style="display: block;">
                      <h6>₦{{ Number(item.amount).toLocaleString() }}</h6>
                      <h6>{{ item.type }}</h6>
                    </div>
                  </div>
                </div>
                <div
                  class="transaction-items"
                  v-if="activeTransactionTab === 2"
                >
                  <div
                    class="item-holder-t"
                    v-for="(item, index) in pendingApproval"
                    :key="index"
                  >
                    <div>
                      <img
                        v-if="index % 2 === 0"
                        src="../../assets/icons/icon-bills.svg"
                        alt="payment"
                      />
                      <img
                        v-if="index % 2 !== 0"
                        src="../../assets/icons/icon-payment-check.svg"
                        alt="payment"
                      />
                      <div>
                        <h4>{{ item.firstname }} {{ item.lastname }}</h4>
                        <h5>{{ item.created_at | moment }}</h5>
                      </div>
                    </div>
                    <h6>{{ item.gender }}</h6>
                    <!-- <h6>₦100,000</h6> -->
                  </div>
                </div>
                <!-- <div class="view-more">
                  <p>View More Activities</p>
                </div> -->
              </div>
            </div>
          </div>
          <!-- transactions history and details ends -->
        </div>
        <div class="col-md-3">
          <div class="notification">
            <!-- <div class="total-commission-holder">
              <h6>Total Commission</h6>
              <h3>₦0,000</h3>
              <div class="commission-details">
                <p>Card purchases: ₦0,000</p>
                <p>Interest charges: ₦0,000</p>
                <p>Vendor transaction: ₦0,000</p>
              </div>
            </div> -->
            <div class="notification-section">
              <p class="notification-text">Notifications</p>
              <div class="">
                <div
                  class="notification-item"
                  data-toggle="modal"
                  data-target="#notificationdetails"
                >
                  <img src="../../assets/icons/person-reg.svg" alt="reg" />
                  <div>
                    <h2>
                      You received a payment of ₦00,000 from Steve Job. The
                      funds have been depo...
                    </h2>
                    <p>Apr 3, 2024</p>
                  </div>
                </div>
                <div class="notification-item">
                  <img src="../../assets/icons/person-reg.svg" alt="reg" />
                  <div>
                    <h2>New user registered</h2>
                    <p>Apr 3, 2024</p>
                  </div>
                </div>
                <div class="notification-item">
                  <img src="../../assets/icons/person-reg.svg" alt="reg" />
                  <div>
                    <h2>Card Aactivated</h2>
                    <p>Apr 3, 2024</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Payment approval modal starts -->
      <div
        class="modal fade"
        id="paymentapproval"
        tabindex="-1"
        aria-labelledby="paymentapproval"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Payment Approval
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- <div class="header-text">
                    <h6>Request Payment</h6>
                  </div> -->
            <div class="modal-body payment-approval-body" v-if="showPaymentRes">
              <div class="approval-header">
                <small>You are sending</small>
                <h4>
                  ₦{{
                    Number(requestPaymentArray.full_payment).toLocaleString()
                  }}
                </h4>
                <h6>to</h6>
                <h5>
                  {{ requestPaymentArray.vendor.firstname }}
                  {{ requestPaymentArray.vendor.lastname }}
                </h5>
              </div>
              <div class="approval-reequest-details">
                <h6 class="transaction-details-text">
                  Transaction details
                </h6>
                <div class="approval-item">
                  <h5>Available balance</h5>
                  <h5>
                    ₦{{
                      Number(requestPaymentArray.safe_balance).toLocaleString()
                    }}
                  </h5>
                </div>
                <!-- <div class="approval-item">
                        <h5>Debit from balance (₦)</h5>
                        <h5>10,000</h5>
                      </div> -->
                <div class="approval-item">
                  <h5>Ovadraft amount</h5>
                  <h5>
                    ₦{{
                      Number(requestPaymentArray.overdraft).toLocaleString()
                    }}
                  </h5>
                </div>
                <div class="approval-item">
                  <h5>Ovadraft charges</h5>
                  <h5>
                    ₦{{ Number(requestPaymentArray.charge.toLocaleString()) }}
                  </h5>
                </div>
                <hr />
                <div class="approval-item-2">
                  <h5>Total debit</h5>
                  <h5>
                    ₦{{
                      Number(requestPaymentArray.total_debit).toLocaleString()
                    }}
                  </h5>
                </div>
                <div class="approval-item-2">
                  <h5>Outstanding balance</h5>
                  <h5
                    class=""
                    :class="[
                      requestPaymentArray.outstanding_balance > 0
                        ? 'outstanding-balance'
                        : 'balance-after-transaction'
                    ]"
                  >
                    ₦{{
                      Number(
                        requestPaymentArray.outstanding_balance
                      ).toLocaleString()
                    }}
                  </h5>
                </div>
                <div class="approval-item-2 balance-after-transaction">
                  <h5>Balance after transaction</h5>
                  <h5
                    class=""
                    :class="[
                      requestPaymentArray.balance_after_transaction > 0
                        ? 'outstanding-balance'
                        : 'balance-after-transaction'
                    ]"
                  >
                    ₦{{
                      Number(
                        requestPaymentArray.balance_after_transaction
                      ).toLocaleString()
                    }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="modal-footer" style="border: none; flex-wrap: nowrap;">
              <button
                @click="
                  closeRequestModal();
                  acceptTransaction = 'decline';
                  transactionType = 'payment';
                "
                type="button"
                class="btn cancel-btn"
                data-toggle="modal"
                data-target="#approvalpin"
              >
                Decline
              </button>
              <button
                @click="
                  closeRequestModal();
                  acceptTransaction = 'accept';
                  transactionType = 'payment';
                "
                type="button"
                class="btn continue-btn"
                data-toggle="modal"
                data-target="#approvalpin"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Payment approval modal ends -->

      <!-- payment approval pin modal starts -->
      <div
        class="modal fade"
        id="approvalpin"
        tabindex="-1"
        aria-labelledby="approvalpin"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Enter Transaction Pin
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <hr />
            <div class="modal-body payment-approval-body">
              <div class="transaction-pin">
                <input
                  @keyup.prevent="moveToNextField('secondInput')"
                  ref="firstInput"
                  type="number"
                  class="form-control input-pin"
                  maxlength="1"
                  v-model="firstpin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <input
                  @keyup.prevent="moveToNextField('thirdInput')"
                  ref="secondInput"
                  type="number"
                  class="form-control input-pin"
                  maxlength="1"
                  v-model="secondpin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <input
                  @keyup.prevent="moveToNextField('fouthInput')"
                  ref="thirdInput"
                  type="number"
                  class="form-control input-pin"
                  maxlength="1"
                  v-model="thirdpin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <input
                  @keyup.prevent="moveToNextField('fouthInput')"
                  ref="fouthInput"
                  type="number"
                  class="form-control input-pin"
                  maxlength="1"
                  v-model="fourthpin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
              </div>
            </div>
            <div class="modal-footer" style="border: none; flex-wrap: nowrap;">
              <button
                v-if="transactionType === 'payment'"
                :disabled="
                  requestPaymentLoader ||
                    firstpin == '' ||
                    secondpin == '' ||
                    thirdpin == '' ||
                    fourthpin == ''
                "
                type="button"
                class="btn continue-btn small-btn"
                @click="approveRequestPayment()"
              >
                Submit
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 pinloader"
                  v-if="requestPaymentLoader"
                ></i>
              </button>
              <button
                v-else
                :disabled="
                  pinloader3 ||
                    firstpin == '' ||
                    secondpin == '' ||
                    thirdpin == '' ||
                    fourthpin == ''
                "
                type="button"
                class="btn continue-btn small-btn"
                @click="approveRejectCardUpgrade()"
              >
                Submit
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 pinloader"
                  v-if="pinloader3"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- payment approval pin modal ends  -->

      <!-- modal for vendor to request payments starts -->
      <div
        class="modal fade"
        id="requestpayment"
        tabindex="-1"
        aria-labelledby="requestpayment"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <!-- <h5 class="modal-title title-modal" id="fundwallet">
              Fund Wallet -->
              <!-- </h5> -->
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="header-text">
              <h6>Request Payment</h6>
            </div>
            <div class="modal-body reg-body">
              <form>
                <div class="form-group theform">
                  <label
                    v-if="pinloader"
                    for="surname"
                    class="d-flex mt-1 label-tags loading-card-details"
                    >Loading card details</label
                  >
                  <label v-else for="cardnumber" class="d-flex mt-1 label-tags"
                    >Card number/Card pin</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control input-field"
                      type="number"
                      id="cardnumber2"
                      name="cardnumber"
                      placeholder="1234567890"
                      v-model="cardpin"
                    />
                    <div class="input-group-append">
                      <img
                        class="input-group-text"
                        id="basic-addon2"
                        style="background: none; cursor: pointer;"
                        src="../../assets/icons/scan-barcode.svg"
                        alt="scan"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="pindetailsshow">
                  <div class="form-group theform">
                    <label for="holdername" class="d-flex mt-1 label-tags"
                      >Card holder's name</label
                    >
                    <input
                      disabled
                      class="form-control input-field"
                      id="holdername"
                      name="holdername"
                      placeholder="John Doe"
                      v-model="cardholdername"
                    />
                  </div>
                  <div class="form-group theform">
                    <label for="name" class="d-flex mt-1 label-tags"
                      >Amount</label
                    >
                    <input
                      class="form-control input-field"
                      id="requestAmount"
                      name="requestAmount"
                      placeholder="Enter amount"
                      v-model="requestAmount2"
                    />
                  </div>
                  <div
                    class="requestamount-notifcation"
                    v-if="requestAmount != ''"
                  >
                    <p>
                      You are requesting a payment of
                      <span>₦{{ Number(requestAmount).toLocaleString() }}</span>
                      from
                      <span>{{ cardholdername }}</span>
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer" style="border: none;">
              <button
                :disabled="
                  !pindetailsshow || payrequestloading || requestAmount === ''
                "
                type="button"
                class="btn btn-block continue-btn"
                @click="vendorRequestPayment()"
              >
                Proceed
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="payrequestloading"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal for vendor to request payments ends -->

      <!-- modal for users to upgrade card plans starts -->
      <!-- <div
        class="modal fade"
        id="cardupgradestarts"
        tabindex="-1"
        aria-labelledby="cardupgradestarts"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <button
                @click="cardpin = ''"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="header-text">
              <h6>Request Card Upgrade</h6>
            </div>
            <div class="modal-body reg-body">
              <form>
                <div class="form-group theform">
                  <label
                    v-if="pinloader"
                    for="surname"
                    class="d-flex mt-1 label-tags loading-card-details"
                    >Loading card details</label
                  >
                  <label v-else for="cardpin" class="d-flex mt-1 label-tags"
                    >Card number/pin</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control input-field"
                      id="cardpin"
                      name="cardpin"
                      placeholder="1234567890"
                      v-model="cardpin"
                    />
                    <div class="input-group-append">
                      <img
                        class="input-group-text"
                        id="basic-addon2"
                        style="background: none; cursor: pointer;"
                        src="../../assets/icons/scan-barcode.svg"
                        alt="scan"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer" style="border: none;">
              <button
                :disabled="pinloader || !activateBtn || cardpin.length < 12"
                type="button"
                class="btn btn-block continue-btn"
                @click="openCardDetailsModal()"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <!-- card upgrade options starts -->
      <div
        class="modal fade"
        id="cardupgradeplans"
        tabindex="-1"
        aria-labelledby="cardupgradeplans"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Upgrade Plan
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="header-text">
              <h6>Compare features to select the best tier for you.</h6>
            </div>
            <div class="modal-body reg-body" style="margin-bottom: 30px">
              <form>
                <div class="upgrade-card">
                  <div>
                    <div
                      class="card-upgrade-option"
                      @click="
                        openNewCardDetailsModal();
                        cardRequestType = 'oldcard';
                      "
                    >
                      <div class="d-flex align-item-center">
                        <img
                          src="../../assets/icons/payment-method-icon.svg"
                          alt="card"
                        />
                        <div>
                          <h5>Gold card <span>Current</span></h5>
                          <h6>₦3,000</h6>
                        </div>
                      </div>
                      <div>
                        <i class="fa fa-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="upgrade-card">
                  <div>
                    <div
                      class="card-upgrade-option"
                      @click="
                        openNewCardDetailsModal();
                        cardRequestType = 'oldcard';
                      "
                    >
                      <div class="d-flex align-item-center">
                        <img
                          src="../../assets/icons/payment-method-icon.svg"
                          alt="card"
                        />
                        <div>
                          <h5>Ruby card</h5>
                          <h6>₦4,000</h6>
                        </div>
                      </div>
                      <div>
                        <i class="fa fa-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="upgrade-card">
                  <div>
                    <div class="card-upgrade-option">
                      <div class="d-flex align-item-center">
                        <img
                          src="../../assets/icons/payment-method-icon.svg"
                          alt="card"
                        />
                        <div>
                          <h5>Platinum card</h5>
                          <h6>₦5,000</h6>
                        </div>
                      </div>
                      <div>
                        <i class="fa fa-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- card upgrade options ends -->

      <!-- card upgrade details starts -->
      <div
        class="modal fade"
        id="cardupgradedetails"
        tabindex="-1"
        aria-labelledby="cardupgradedetails"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <!-- <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Upgrade Plan
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> -->
            <div class="back-chevron" @click="openCardpinModal()">
              <i class="fa fa-chevron-left"></i>
            </div>
            <div class="modal-body reg-body" style="margin-bottom: 0px">
              <div class="top-detail">
                <h5 v-if="carddetails.cardtype">
                  {{ carddetails.cardtype.cardname }}
                </h5>
                <h2 v-if="carddetails.cardtype">
                  ₦{{ Number(carddetails.cardtype.amount).toLocaleString() }}
                </h2>
                <h6>This card requires a one-time purchase</h6>
              </div>
              <form>
                <div class="card-details-holder">
                  <div class="card-details-item">
                    <h4>Minimum daily savings</h4>
                    <h4>₦500</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Transaction fee</h4>
                    <h4>5%</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Max. trans. limit</h4>
                    <h4 v-if="carddetails.cardtype">
                      ₦{{
                        Number(
                          carddetails.cardtype.transaction_limit
                        ).toLocaleString()
                      }}
                    </h4>
                  </div>
                  <!-- <div class="card-details-item">
                    <h4>Usage</h4>
                    <h4>Medical needs</h4>
                  </div> -->
                  <!-- <div class="card-details-item">
                    <h4>Monthly card admin. fee</h4>
                    <h4>₦299</h4>
                  </div> -->
                  <div class="card-details-item">
                    <h4>Insurance</h4>
                    <h4>1%</h4>
                  </div>
                </div>
                <div
                  v-if="carddetails.cardtype"
                  class="modal-footer"
                  style="border: none; margin-top: 24px;"
                >
                  <button
                    v-if="carddetails.cardtype"
                    type="button"
                    class="btn btn-block continue-btn"
                    @click="openUpgradeCardPaymentModal()"
                  >
                    Select {{ carddetails.cardtype.cardname }} Card
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- card upgrade details ends -->

      <!-- New card details starts -->
      <div
        class="modal fade"
        id="newcarddetails"
        tabindex="-1"
        aria-labelledby="newcarddetails"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <!-- <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Upgrade Plan
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> -->
            <div class="back-chevron" @click="openNewCarddetailsModal()">
              <i class="fa fa-chevron-left"></i>
            </div>
            <div class="modal-body reg-body" style="margin-bottom: 0px">
              <div class="top-detail">
                <h5 v-if="cardTypeDetails">
                  {{ cardTypeDetails.cardname }}
                </h5>
                <h2 v-if="cardTypeDetails">
                  ₦{{ Number(cardTypeDetails.amount).toLocaleString() }}
                </h2>
                <h6>This card requires a one-time purchase</h6>
              </div>
              <form>
                <div class="card-details-holder">
                  <div class="card-details-item">
                    <h4>Max. trans. limit</h4>
                    <h4 v-if="cardTypeDetails">
                      ₦{{
                        Number(
                          cardTypeDetails.transaction_limit
                        ).toLocaleString()
                      }}
                    </h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Insurance</h4>
                    <h4>1%</h4>
                  </div>
                </div>
                <div
                  class="modal-footer"
                  style="border: none; margin-top: 24px;"
                >
                  <button
                    type="button"
                    class="btn btn-block continue-btn"
                    @click="openUpgradeCardPaymentModal()"
                  >
                    Select {{ cardTypeDetails.cardname }} Card
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- New card details ends -->

      <!-- card upgrade payment method starts -->
      <!-- <div
        class="modal right-modal fade"
        id="cardupgradepayment"
        tabindex="-1"
        aria-labelledby="cardupgradepayment"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-center"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Payment Method
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="header-text">
              <h6>Choose how you would love to make this payment.</h6>
            </div>
            <hr />
            <div class="modal-body">
              <div class="top-detail">
                <h5>Ruby Card</h5>
                <h2>₦3,000</h2>
                <h6>Change plan</h6>
              </div>
              <hr />
              <div class="payment-select">
                <button
                  class="btn"
                  :class="[
                    paymentSelectOption === 'select'
                      ? 'active-select-option'
                      : ''
                  ]"
                  @click="paymentSelectOption = 'select'"
                >
                  <img
                    src="../../assets/icons/card-upgrade.svg"
                    alt="upgrade"
                  />
                  <p>SAFE</p>
                </button>
                <button
                  class="btn"
                  :class="[
                    paymentSelectOption === 'agent-vendor'
                      ? 'active-select-option'
                      : ''
                  ]"
                  @click="paymentSelectOption = 'agent-vendor'"
                >
                  <img
                    src="../../assets/icons/card-upgrade.svg"
                    alt="upgrade"
                  />
                  <p>Cash</p>
                </button>
              </div>
              <div class="d-none" v-if="paymentSelectOption === 'select'">
                <div class="payments-buttons">
                  <button
                    @click="paymentMethod = 'safe'"
                    :class="[
                      paymentMethod === 'safe' ? 'activePaymentMethod' : ''
                    ]"
                    data-toggle="modal"
                    data-target="#confirmation"
                  >
                    <div>
                      <img
                        src="../../assets/icons/payment-method-safe.svg"
                        alt="safe"
                      />
                      SAFE
                    </div>
                    <div
                      class="check-click"
                      :class="[
                        paymentMethod === 'safe' ? 'activeCheckBox' : ''
                      ]"
                    >
                      <i
                        class="fa fa-check"
                        v-if="paymentMethod === 'safe'"
                      ></i>
                    </div>
                  </button>
                </div>
                <div class="payments-buttons">
                  <button
                    data-toggle="modal"
                    data-target="#confirmation"
                    @click="paymentMethod = 'cooperative'"
                    :class="[
                      paymentMethod === 'cooperative'
                        ? 'activePaymentMethod'
                        : ''
                    ]"
                  >
                    <div>
                      <img
                        src="../../assets/icons/payment-method-cooperative.svg"
                        alt="safe"
                      />
                      Cooperative account
                    </div>
                    <div
                      class="check-click"
                      :class="[
                        paymentMethod === 'cooperative' ? 'activeCheckBox' : ''
                      ]"
                    >
                      <i
                        class="fa fa-check"
                        v-if="paymentMethod === 'cooperative'"
                      ></i>
                    </div>
                  </button>
                </div>
              </div>
              <div
                class="modal-footer pr-0 pl-0"
                style="border: none; margin-top: 24px;"
              >
                <button
                  type="button"
                  class="btn btn-block continue-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Request Upgrade
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- card upgrade payment method ends -->

      <!-- Notification modal details starts -->
      <div
        class="modal fade"
        id="notificationdetails"
        tabindex="-1"
        aria-labelledby="notificationdetails"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <!-- <h5 class="modal-title title-modal" id="fundwallet">
                Card upgrade approval
              </h5> -->
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body reg-body" style="margin-bottom: 30px">
              <!-- <div class="card-upgrade-approval-header">
                <h2>₦3,000</h2>
                <h5>Will be deducted</h5>
                <h4>From your</h4>
                <h6>SAFE</h6>
              </div> -->
              <form>
                <div
                  class="card-details-holder"
                  style="background: none; border-radius: 0;"
                >
                  <h1 class="card-features-text">Notification details</h1>
                  <h5 class="notification-details-text">
                    You received a payment of ₦15,000 from Steve Job. The funds
                    have been deposited into your Safe account. Keep rocking
                    Ovadraft!
                  </h5>
                  <div class="card-details-item">
                    <h4>From</h4>
                    <h4>Steve Jobs</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Amount</h4>
                    <h4>₦10,000</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Time</h4>
                    <h4>16:23:12</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Date</h4>
                    <h4>June 2, 2024</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Reference no</h4>
                    <h4>SW323333</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Status</h4>
                    <h4>SUCCESSFUL</h4>
                  </div>
                </div>
                <div class="modal-footer" style="border: none;">
                  <button
                    type="button"
                    class="btn btn-block continue-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Download Receipt
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Notification modal details ends -->

      <!-- Card Approval requst starts -->
      <div
        v-if="showCardDetails"
        class="modal fade"
        id="cardupgradeapproval"
        tabindex="-1"
        aria-labelledby="cardupgradeapproval"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Card upgrade approval
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body reg-body" style="margin-bottom: 10px">
              <div class="card-upgrade-approval-header">
                <h2 v-if="cardDetails2.user[0]">
                  ₦{{
                    Number(
                      cardDetails2.user[0].cardDetails.amount
                    ).toLocaleString()
                  }}
                </h2>
                <h5>Will be deducted</h5>
                <h4>From your</h4>
                <h6>SAFE</h6>
              </div>
              <form>
                <div
                  class="card-details-holder"
                  style="background: none; border-radius: 0;"
                >
                  <h1 class="card-features-text">Card features</h1>
                  <div class="card-details-item">
                    <h4>Minimum daily savings</h4>
                    <h4>₦500</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Transaction fee</h4>
                    <h4>5%</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Max. trans. limit</h4>
                    <h4 v-if="cardDetails2.user[0]">
                      ₦{{
                        Number(
                          cardDetails2.user[0].cardDetails.transaction_limit
                        ).toLocaleString()
                      }}
                    </h4>
                  </div>
                  <!-- <div class="card-details-item">
                    <h4>Card type</h4>
                    <h4>{{ cardDetails2.user[0].cardDetails. }}</h4>
                  </div> -->
                  <!-- <div class="card-details-item">
                    <h4>Monthly card admin. fee</h4>
                    <h4>₦299</h4>
                  </div> -->
                  <div class="card-details-item">
                    <h4>Insurance</h4>
                    <h4>1%</h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Card number</h4>
                    <h4 v-if="cardDetails2.user[0]">
                      {{ cardDetails2.user[0].cardDetails.card_no }}
                    </h4>
                  </div>
                  <div class="card-details-item">
                    <h4>Card type</h4>
                    <h4 v-if="cardDetails2.user[0]">
                      {{ cardDetails2.user[0].cardType.cardname }}
                    </h4>
                  </div>
                </div>
                <div
                  class="modal-footer"
                  style="border: none; flex-wrap: nowrap;"
                >
                  <button
                    @click="
                      openTransactionPin2();
                      upgradeAction = 'reject';
                    "
                    type="button"
                    class="btn cancel-btn"
                  >
                    Decline
                  </button>
                  <button
                    @click="
                      openTransactionPin2();
                      upgradeAction = 'approve';
                    "
                    type="button"
                    class="btn continue-btn"
                  >
                    Approve
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Card Approval request ends -->

      <!-- modal for users to upgrade card plans ends -->

      <!-- Request new card old users starts -->
      <div
        class="modal fade"
        id="requestcard"
        tabindex="-1"
        aria-labelledby="requestcard"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Request card
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="header-text">
              <h6>Compare features to select the best tier for you.</h6>
            </div>
            <div class="modal-body reg-body" style="margin-bottom: 30px">
              <form>
                <div
                  class="upgrade-card"
                  v-for="(item, index) in ovadraftcards"
                  :key="index"
                >
                  <div>
                    <div
                      class="card-upgrade-option"
                      @click="
                        newCardDetailsModal(item);
                        cardRequestType = 'newcard';
                        cardTypeId = item.id;
                      "
                    >
                      <div class="d-flex align-item-center">
                        <img
                          src="../../assets/icons/payment-method-icon.svg"
                          alt="card"
                        />
                        <div>
                          <h5>{{ item.cardname }} card</h5>
                          <h6>₦{{ Number(item.amount).toLocaleString() }}</h6>
                        </div>
                      </div>
                      <div>
                        <i
                          class="fa fa-spinner fa-spin ml-3 f-12 pinloader"
                          v-if="pinloader"
                        ></i>
                        <i v-else class="fa fa-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="newcardpaymentmethod"
        tabindex="-1"
        aria-labelledby="newcardpaymentmethod"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header pb-0" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Delivery/pickup
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="header-text">
              <h6>Choose how you would love to get your card.</h6>
            </div>
            <div class="modal-body reg-body" style="margin-bottom: 0px">
              <form>
                <div class="payment-select d-none">
                  <button
                    style="width: 100%;"
                    class="btn"
                    :class="[
                      paymentSelectOption === 'select'
                        ? 'active-select-option'
                        : ''
                    ]"
                    @click.prevent="paymentSelectOption = 'select'"
                  >
                    <img
                      src="../../assets/icons/card-upgrade.svg"
                      alt="upgrade"
                    />
                    <p>SAFE</p>
                  </button>
                  <button
                    style="width: 100%;"
                    class="btn"
                    :class="[
                      paymentSelectOption === 'agent-vendor'
                        ? 'active-select-option'
                        : ''
                    ]"
                    @click.prevent="paymentSelectOption = 'agent-vendor'"
                  >
                    <img
                      src="../../assets/icons/card-upgrade.svg"
                      alt="upgrade"
                    />
                    <p>Cash</p>
                  </button>
                </div>

                <div class="d-none" v-if="paymentSelectOption === 'select'">
                  <div class="payments-buttons">
                    <button
                      @click="paymentMethod = 'safe'"
                      :class="[
                        paymentMethod === 'safe' ? 'activePaymentMethod' : ''
                      ]"
                      data-toggle="modal"
                      data-target="#confirmation"
                    >
                      <div>
                        <img
                          src="../../assets/icons/payment-method-safe.svg"
                          alt="safe"
                        />
                        SAFE
                      </div>
                      <div
                        class="check-click"
                        :class="[
                          paymentMethod === 'safe' ? 'activeCheckBox' : ''
                        ]"
                      >
                        <i
                          class="fa fa-check"
                          v-if="paymentMethod === 'safe'"
                        ></i>
                      </div>
                    </button>
                  </div>

                  <div class="payments-buttons">
                    <button
                      data-toggle="modal"
                      data-target="#confirmation"
                      @click="paymentMethod = 'cooperative'"
                      :class="[
                        paymentMethod === 'cooperative'
                          ? 'activePaymentMethod'
                          : ''
                      ]"
                    >
                      <div>
                        <img
                          src="../../assets/icons/payment-method-cooperative.svg"
                          alt="safe"
                        />
                        Cooperative account
                      </div>
                      <div
                        class="check-click"
                        :class="[
                          paymentMethod === 'cooperative'
                            ? 'activeCheckBox'
                            : ''
                        ]"
                      >
                        <i
                          class="fa fa-check"
                          v-if="paymentMethod === 'cooperative'"
                        ></i>
                      </div>
                    </button>
                  </div>
                </div>
                <h6
                  class="newcard-option-text"
                  v-if="paymentSelectOption === 'agent-vendor'"
                >
                  You've chosen to pay cash for your Ovadraft card tier upgrade.
                  Please confirm your choice by clicking the 'Confirm' button
                  below.
                </h6>
                <div>
                  <label class="delivery-pickup-text" for="">Select one</label>
                  <div class="payment-select">
                    <button
                      style="width: 100%;"
                      class="btn"
                      :class="[
                        deliveryOption === 'free' ? 'active-select-option' : ''
                      ]"
                      @click.prevent="deliveryOption = 'free'"
                    >
                      <h6>Pickup from Agent</h6>
                      <p class="reuse">Free</p>
                    </button>
                    <button
                      style="width: 100%;"
                      class="btn"
                      :class="[
                        deliveryOption === 'doorstep'
                          ? 'active-select-option'
                          : ''
                      ]"
                      @click.prevent="deliveryOption = 'doorstep'"
                    >
                      <h6>Doorstep delivery</h6>
                      <p class="reuse">₦3,000</p>
                    </button>
                  </div>
                </div>
                <!-- <div
                  class="delivey-address-snip"
                  v-if="deliveryOption === 'doorstep'"
                >
                  <h6>John Doe</h6>
                  <h6>Wuse 2, Abuja</h6>
                </div> -->
              </form>
              <div class="modal-footer mt-4 pl-0 ml-0" style="border: none;">
                <button
                  :disabled="deliveryOption === ''"
                  @click="newCardOptionModal()"
                  type="button"
                  class="btn btn-block continue-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="deliveryaddress"
        tabindex="-1"
        aria-labelledby="deliveryaddress"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="margin-bottom: 50px;"
        >
          <div class="modal-content">
            <div class="modal-header pb-0" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Add Address
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="header-text">
              <h6>Provide the details of your address</h6>
            </div>
            <div class="modal-body reg-body" style="margin-bottom: 0px">
              <form>
                <div class="form-group theform">
                  <label for="fullname" class="d-flex mt-1 label-tags"
                    >Full name (first name and last name)</label
                  >
                  <input
                    class="form-control input-field"
                    id="fullname"
                    name="fullname"
                    placeholder="Full name"
                    v-model="fullname"
                  />
                </div>
                <div class="form-group theform">
                  <label for="homeaddress" class="d-flex mt-1 label-tags"
                    >Home address</label
                  >
                  <input
                    class="form-control input-field"
                    id="homeaddress"
                    name="homeaddress"
                    placeholder="House address"
                    v-model="homeaddress"
                  />
                </div>

                <div class="form-group theform">
                  <label for="stateid" class="d-flex mt-1 label-tags"
                    >State</label
                  >
                  <select
                    @change="selectState()"
                    name="state"
                    class="form-control select-option"
                    id="stateid"
                    v-model="stateid"
                  >
                    <option selected disabled value="null">--Select--</option>
                    <option
                      v-for="(item, index) in states"
                      :key="index"
                      :value="item"
                      >{{ item.name }}</option
                    >
                  </select>
                </div>

                <div class="form-group theform" v-if="stateid">
                  <label for="cityid" class="d-flex mt-1 label-tags"
                    >City</label
                  >
                  <select
                    class="form-control select-option"
                    id="cityid"
                    v-model="cityid"
                  >
                    <option selected disabled :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in cities"
                      :key="index"
                      :value="item"
                      >{{ item.name }}</option
                    >
                    <!-- <option
                    v-for="(item, index) in modeOfSafeProject"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</option
                  > -->
                  </select>
                </div>

                <div class="form-group theform">
                  <label for="phonenumber" class="d-flex mt-1 label-tags"
                    >Phone number</label
                  >
                  <input
                    maxlength="11"
                    type="number"
                    class="form-control input-field"
                    id="phonenumber"
                    name="phonenumber"
                    placeholder="Phone number"
                    v-model="phonenumber"
                    onfocus="text"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                </div>
              </form>
              <div class="modal-footer mt-4 pl-0 ml-0" style="border: none;">
                <button
                  :disabled="newcardloader"
                  @click="oldUserRequestCard()"
                  type="button"
                  class="btn btn-block continue-btn"
                >
                  Submit
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="newcardloader"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal to select or add new guarantor starts -->
      <div
        tabindex="-1"
        class="modal fade"
        id="agents"
        role="dialog"
        aria-labelledby="agents"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="margin-bottom: 30px">
            <div class="modal-header pb-0" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Agents
              </h5>
              <button
                @click="activeG2 = ''"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="header-text">
              <h6>Here are our available agent and their details</h6>
            </div>
            <div class="guarantor-boby">
              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      style="background: none; border: 1px solid #D0D5DD;"
                      ><i class="fa fa-search"></i
                    ></span>
                  </div>
                  <input
                    style="border-left: none; padding-left: 0 !important; outline: 0; box-shadow: none;"
                    @keyup="filterOvadraftAgent(searchsg)"
                    :disabled="loadingFilter"
                    type="text"
                    class="form-control"
                    name="filter"
                    id="filter"
                    v-model="searchsg"
                    placeholder="Search for a agent  by first name"
                  />
                  <!-- <div class="input-group-append">
                  <span class="input-group-text" style="cursor: pointer"
                    >Search</span
                  >
                </div> -->
                </div>
              </div>
              <div>
                <div class="guarantor" id="scrollbar-target">
                  <div
                    @click="
                      activeG2 = index;
                      ovadraftAgentItem(item);
                    "
                    class="agents-holder"
                    :class="[activeG2 === index ? 'activeGuarantor' : '']"
                    v-for="(item, index) in ovadraftAgents"
                    :key="'A' + index"
                  >
                    <small :class="[activeG2 === index ? 'activeName' : '']">{{
                      item.initials
                    }}</small>
                    <div
                      class="d-flex justify-content-between align-items-center"
                      style="width: 100%"
                    >
                      <div>
                        <h5>{{ item.firstname }} {{ item.lastname }}</h5>
                        <h6>{{ item.phone }}</h6>
                        <h6>{{ item.address }}</h6>
                      </div>
                      <div
                        class="select-holder"
                        :class="[activeG2 === index ? 'activeCheck' : '']"
                      >
                        <i v-if="index === activeG2" class="fa fa-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="gurantor-btn">
              <button
                type="button"
                class="btn cancel"
                @click="activeG = ''"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn continue"
                data-dismiss="modal"
                :disabled="agentId === '' || newcardloader"
                @click="oldUserRequestCard()"
              >
                Submit
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="newcardloader"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal to select or add new guarantor starts -->
      <!-- Request new card old users ends -->

      <new-user-modal></new-user-modal>
      <transaction-pin-modal></transaction-pin-modal>
    </div>
  </div>
</template>

<script>
import { Service } from "../../store/service";
const Api = new Service();
import PageLoader from "../PageLoader.vue";
import $ from "jquery";
import moment from "moment";
import Vue from "vue";
import NewUserModal from "./NewUserModal.vue";
import TransactionPinModal from "./TransactionPinModal.vue";

export default {
  name: "VendorAgent",
  components: {
    NewUserModal,
    TransactionPinModal,
    PageLoader
  },
  data() {
    return {
      loadpage: false,
      loader: false,
      pinloader: false,
      pinloader2: false,
      pinloader3: false,
      pindetailsshow: false,
      pindetailsshow2: false,
      activateBtn: false,
      payrequestloading: false,
      requestPaymentLoader: false,
      showCardDetails: false,
      showPaymentRes: false,
      newcardloader: false,
      loadingFilter: false,
      historyTransaction: false,
      activeTransactionTab: 1,
      user: "",
      safebalance: "",
      // upgradeholdername: "Tonia Kevwe",
      // upgradeemail: "toniakevwe@gmail.com",
      paymentMethod: "",
      paymentSelectOption: "",
      deliveryOption: "",
      cardRequestType: "",
      activeG: "",
      activeG2: "",
      guarantorsSmart: [],
      guarantorId: "",
      guarantoraddress: "",
      guarantorrelationship: "",
      guarantorphone: "",
      guarantoremail: "",
      guarantorfullname: "",
      cardpin: "",
      cardpin2: "",
      searchsg: "",
      transactionPin: "",
      upgradeAction: "",
      firstpin: "",
      secondpin: "",
      thirdpin: "",
      fourthpin: "",
      cardholdername: "",
      requestAmount: "",
      requestAmount2: "",
      // to be checked
      fullname: "",
      phonenumber: "",
      cityid: "",
      city: "",
      cities: "",
      stateid: "",
      state: "",
      states: "",
      homeaddress: "",
      carddetails: "",
      cardDetails2: "",
      paymentRequestDetails: "",
      requestPaymentArray: [],
      acceptTransaction: "",
      transactionType: "",
      ovadraftcards: [],
      cardTypeId: "",
      cardTypeDetails: {},
      ovadraftAgents: [],
      notifications: [],
      pendingApproval: [],
      userOverdraftCard: "",
      user2: "",
      fulldata: "",
      pickuptype: "",
      agentId: "",
      splitpart: "",
      transactionHistory: [],
      vendorCount: {},
      agentCount: {},
      requesturl: "",
      cardrequests: []
      // tobe checkded again
    };
  },
  created() {
    this.requestPaymentArray = [];
    this.$root.$refs.usersCreated = this;
    // this.pendingActivation();
    // this.fetchTransactionHistory();
    //     this.user = JSON.parse(localStorage.getItem("shelta-user"));
  },
  watch: {
    cardpin: function(newValue, oldValue) {
      if (newValue.length == 12) {
        this.getDetails();
      }
    },
    requestAmount2: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.requestAmount2 = result));
      this.requestAmount = this.requestAmount2.split(",").join("");
    },
    searchsg: function(newValue) {
      if (newValue.length == 0 || newValue == "") {
        this.filterednow = false;
        this.ovadraftAgents = [];
      }
      // if (this.ovadraftAgents.length == 0) {
      //   this.ovadraftAgents = [];
      //   return this.smartOvadraftAgents();
      // }
    }
  },
  // created() {
  //   // Perform initialization logic
  //   this.paymentRequestDetails = "";
  //   this.cardDetails2 = "";
  // },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    if (this.user.overdraft_user_type === null) {
      this.$router
        .push({
          path: `/dashboard/join-ovadraft`
        })
        .catch(() => {});
    }
    if (this.user.overdraft_user_type === "agent") {
      this.fetchCustomerCardRequests();
    }
    this.smartOvadraftAgents();
    // this.getDetails();
    this.getOvadraftCards();
    this.fetchCardUpgradeRequest();
    this.fetchNotification();
    this.getUserProfile();
    this.getStates();
    this.fetchTransactionHistory();
    this.pendingActivation();
    this.fetchVendorCount();
    this.fetchAgentCount();
    // this.fetchRequestPayment();
    // this.getVendorsUnlinkedRegisteredUser();
  },
  methods: {
    openPaymentApproval() {
      $("#paymentapproval")
        .addClass("fade")
        .modal("show");
    },
    openNewCardPaymentModal() {
      $("#newcarddetails")
        .removeClass("fade")
        .modal("hide");
      $("#cardupgradeapproval")
        .removeClass("fade")
        .modal("hide");
    },
    openNewCarddetailsModal() {
      $("#newcarddetails")
        .removeClass("fade")
        .modal("hide");
      $("#requestcard")
        .addClass("fade")
        .modal("show");
    },
    closeRequestModal() {
      $("#paymentapproval")
        .removeClass("fade")
        .modal("hide");
      $("#cardupgradeapproval")
        .removeClass("fade")
        .modal("hide");
    },
    openCardpinModal() {
      $("#cardupgradedetails")
        .removeClass("fade")
        .modal("hide");
      $("#cardupgradestarts")
        .addClass("fade")
        .modal("show");
    },
    // upgradePlansModal
    upgradePlansCardDetailsModal() {
      $("#cardupgradestarts")
        .removeClass("fade")
        .modal("hide");
      $("#cardupgradedetails")
        .removeClass("fade")
        .modal("hide");
      $("#cardupgradeplans")
        .addClass("fade")
        .modal("show");
    },
    openCardDetailsModal() {
      $("#cardupgradestarts")
        .removeClass("fade")
        .modal("hide");
      $("#cardupgradedetails")
        .addClass("fade")
        .modal("show");
    },
    newCardDetailsModal(details) {
      this.cardTypeDetails = details;
      $("#requestcard")
        .removeClass("fade")
        .modal("hide");
      $("#newcarddetails")
        .addClass("fade")
        .modal("show");
    },
    opencardupgrade() {
      $("#cardupgradestarts")
        .addClass("fade")
        .modal("show");
    },
    openUpgradeCardPaymentModal() {
      $("#cardupgradedetails")
        .removeClass("fade")
        .modal("hide");
      $("#newcarddetails")
        .removeClass("fade")
        .modal("hide");

      if (this.cardRequestType === "oldcard") {
        return $("#cardupgradepayment")
          .addClass("fade")
          .modal("show");
      }
      if (this.cardRequestType === "newcard") {
        return $("#newcardpaymentmethod")
          .addClass("fade")
          .modal("show");
      }
    },
    newCardOptionModal() {
      $("#newcardpaymentmethod")
        .removeClass("fade")
        .modal("hide");
      if (this.deliveryOption === "free") {
        $("#agents")
          .addClass("fade")
          .modal("show");
      }
      if (this.deliveryOption === "doorstep") {
        $("#deliveryaddress")
          .addClass("fade")
          .modal("show");
      }
    },
    openPinModal() {
      this.$root.$refs.createpinmodal.openTransactionPin();
    },
    ovadraftAgentItem(data) {
      this.activeG = "";
      this.agentId = data.id;
      this.guarantorType = data.type;
    },
    async filterOvadraftAgent(string) {
      this.filterednow = true;
      const regexp = new RegExp(string, "i");
      // const regexp2 = new RegExp(string, "i");
      const filtered = this.ovadraftAgents.filter(x =>
        regexp.test(x.firstname)
      );
      this.ovadraftAgents = [];
      this.ovadraftAgents = filtered;
      if (this.ovadraftAgents.length == 0 || this.searchsg === "") {
        this.ovadraftAgents = [];
        await this.smartOvadraftAgents();
      }
    },
    async smartOvadraftAgents() {
      // if (this.searchsg === "") {
      //   this.filterednow = false;
      // } else {
      //   this.filterednow = true;
      // }
      this.loadingFilter = true;
      try {
        // fetchoverdraftagents
        this.ovadraftAgents = [];
        await Api.getRequest(`fetchoverdraftagents`)
          .then(res => {
            this.loadingFilter = false;
            if (res.data.success) {
            // console.log("Agents list", res);
              const agents = res.data.users;
              agents.forEach(element => {
                const y = element.firstname.split(" ");
                if (element.lastname != null) {
                  const iniFName = element.lastname.charAt(0);
                  const iniLname = y[y.length - 1].charAt(0);
                  // const x = iniLname + iniFName;
                  // element.initials = x;
                  const x = iniLname + iniFName;
                  element.initials = x;
                  this.guarantorsSmart.push(element);
                  this.ovadraftAgents.push(element);
                } else {
                  const iniLname = y[y.length - 1].charAt(0);
                  const x = iniLname;
                  element.initials = x;
                  this.guarantorsSmart.push(element);
                  this.ovadraftAgents.push(element);
                }
              });
            }
            if (res.data.error) {
              this.loadingFilter = false;
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loadingFilter = false;
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    openRegistrationModal(propInfo) {
      // this.resData = "loading";

      // this.propertyObject = propInfo;
      // this.fundLoader = true;
      // this.modalData.amount = 0;
      // this.modalData.payableAmount = Number(propInfo.paynowvalue);
      // this.modalData.subtitle = "Payment Amount";
      // this.modalData.paymentType = "rentpayment";
      // this.modalData.headerCaption = "RENT PAYMENT";
      // this.modalData.headerBackground = "#0033ea";

      // this.modalData.paymentURL = "";
      this.$root.$refs.usermodal.openRegistrationModal(propInfo);
    },
    async getDetails() {
      this.pinloader = true;
      this.pinloader2 = true;
      this.activateBtn = false;
      this.pindetailsshow = false;
      // this.pindetailsshow2 = false;
      try {
        await Api.getRequest(`getcarddetailsbypin/${this.cardpin}`)
          .then(res => {
            this.pinloader = false;
            this.pinloader2 = false;
            if (res.data.success) {
              this.activateBtn = true;
              this.pindetailsshow = true;
              // this.pindetailsshow2 = true;
              this.carddetails = res.data.card;
              this.cardholdername =
                this.carddetails.user.firstname +
                " " +
                this.carddetails.user.lastname;
              // return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              this.pinloader = false;
              this.pinloader2 = false;
              this.pindetailsshow = false;
              // this.pindetailsshow2 = false;
              // this.cardholdername = "";
              this.activateBtn = false;
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.pinloader = false;
            this.pindetailsshow = false;
            // this.pindetailsshow2 = false;
            this.activateBtn = false;
            // this.cardholdername = "";
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async fetchCardUpgradeRequest() {
      this.loadpage = true;
      try {
        await Api.getRequest(`fetchoverdraftcardupgraderequest`)
          .then(res => {
            this.fetchRequestPayment();
            this.loadpage = false;
            if (res.data.success) {
              if (res.data.user.length > 0) {
                this.showCardDetails = true;
                this.cardDetails2 = res.data;
              }
              // return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loadpage = false;
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async fetchRequestPayment() {
      this.loadpage = true;
      try {
        await Api.getRequest(`fetchuserrequestoverdraftpayment`)
          .then(res => {
            this.loadpage = false;
            if (res.data.success) {
              if (res.data.request.length > 0) {
                this.showPaymentRes = true;
                this.paymentRequestDetails = res.data;
                this.requestPaymentArray = res.data.request[0];
              }
              // return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loadpage = false;
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async upgradeCard() {
      this.upgradeCardloader = true;
      try {
        await Api.postRequest(`vendorupgradecard`, {
          user_user_id: this.userid,
          card_pin: this.cardpin
        })
          .then(res => {
            this.upgradeCardloader = false;
            if (res.data.success) {
              return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.upgradeCardloader = false;
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    openTransactionPin2() {
      $("#cardupgradeapproval")
        .removeClass("fade")
        .modal("hide");
      $("#approvalpin")
        .addClass("fade")
        .modal("show");
    },
    async approveRejectCardUpgrade() {
      this.pinloader3 = true;
      this.transactionPin =
        this.firstpin + this.secondpin + this.thirdpin + this.fourthpin;
      try {
        await Api.postRequest("userapproveorrejectcardupgrade", {
          action: this.upgradeAction,
          card_pin: this.cardDetails2.user[0].cardDetails.card_no,
          overdraftpin: Number(this.transactionPin)
        })
          .then(res => {
            this.pinloader3 = true;
            $("#approvalpin")
              .removeClass("fade")
              .modal("hide");
            this.$router.go();
            if (res.data.success) {
              this.fetchCardUpgradeRequest();
              return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              this.fetchCardUpgradeRequest();
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.pinloader3 = false;
            $("#approvalpin")
              .removeClass("fade")
              .modal("hide");
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    moveToNextField(nextField) {
      this.$refs[nextField].focus();
    },
    async getOvadraftCards() {
      try {
        await Api.getRequest(`fetchoverdraftcardtypes`)
          .then(res => {
            if (res.data.success) {
              this.ovadraftcards = res.data.over_card_type_types;
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.pinloader = false;
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async vendorRequestPayment() {
      this.payrequestloading = true;
      if (
        Number(this.requestAmount) > Number(this.carddetails.transaction_limit)
      ) {
        this.payrequestloading = false;
        return this.$store.commit("setApiFailed", "transaction limit exceeded");
      }
      try {
        Api.postRequest("vendorrequestoverdraftpayment", {
          card_no: this.cardpin,
          full_payment: Number(this.requestAmount)
        })
          .then(res => {
            this.payrequestloading = false;
            if (res.data.success) {
              $("#requestpayment")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.payrequestloading = false;
            this.pindetailsshow = false;
            this.cardholdername = "";
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async approveRequestPayment() {
      this.requestPaymentLoader = true;
      if (this.acceptTransaction === "accept") {
        this.requesturl = `approveoverdraftpaymentrequest`;
      }
      if (this.acceptTransaction === "decline") {
        this.requesturl = `rejectoverdraftpaymentrequest`;
      }
      this.transactionPin =
        this.firstpin + this.secondpin + this.thirdpin + this.fourthpin;
      try {
        Api.postRequest(this.requesturl, {
          payment_request_id: this.requestPaymentArray.id,
          overdraftpin: Number(this.transactionPin)
        })
          .then(res => {
            this.requestPaymentLoader = false;
            if (res.data.success) {
              this.firstpin = "";
              this.secondpin = "";
              this.thirdpin = "";
              this.fourthpin = "";
              $("#approvalpin")
                .removeClass("fade")
                .modal("hide");
              this.fetchRequestPayment();
              this.$router.go();
              return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.requestPaymentLoader = false;
            $("#approvalpin")
              .removeClass("fade")
              .modal("hide");
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async oldUserRequestCard() {
      if (this.deliveryOption === "free") {
        this.pickuptype = "from_agent";
      }
      if (this.deliveryOption === "doorstep") {
        this.pickuptype = "door_step";
      }
      this.newcardloader = true;
      if (this.deliveryOption === "free") {
        if (this.agent_pickup_id === "") {
          this.newcardloader = false;
          return this.$store.commit("setApiFailed", "Agent Id is required");
        }
      }
      if (this.deliveryOption === "doorstep") {
        if (this.fullname === "") {
          this.newcardloader = false;
          return this.$store.commit("setApiFailed", "Fullname is required");
        }
        if (this.homeaddress === "") {
          this.newcardloader = false;
          return this.$store.commit("setApiFailed", "Address is required");
        }
        if (this.stateid === "") {
          this.newcardloader = false;
          return this.$store.commit("setApiFailed", "State is required");
        }
        if (this.cityid === "") {
          this.newcardloader = false;
          return this.$store.commit("setApiFailed", "City is required");
        }
        if (this.phonenumber === "") {
          this.newcardloader = false;
          return this.$store.commit("setApiFailed", "Phone number is required");
        }
      }
      if (this.cardTypeDetails === "") {
        this.newcardloader = false;
        return this.$store.commit("setApiFailed", "Card type id is required");
      }
      try {
        if (this.deliveryOption === "doorstep") {
          this.splitpart = this.stateid.name.split(" ")[0];
        }
        Api.postRequest("overdraftuserrequestforcard", {
          fullname: this.fullname,
          pickup_address_type: this.pickuptype,
          card_type_id: this.cardTypeId,
          agent_pickup_id: this.agentId,
          street_address: this.homeaddress,
          city: this.cityid.name,
          state: this.splitpart,
          phone: this.phonenumber
        })
          .then(res => {
            this.newcardloader = false;
            if (res.data.success) {
              $("#agents")
                .removeClass("fade")
                .modal("hide");
              $("#deliveryaddress")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.newcardloader = false;
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async fetchNotification() {
      try {
        await Api.getRequest(`fetchuseroverdraftnotifications`)
          .then(res => {
            if (res.data.success) {
              this.notifications = res.data.overdraft_notifications;
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async pendingActivation() {
      try {
        await Api.getRequest("fetchreferredoverdraftuserspendingapproval")
          .then(res => {
            // console.log("ressssssss", res);
            this.pendingApproval = res.data.pending;
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async fetchCustomerCardRequests() {
      try {
        await Api.getRequest("agentfetchuseroverdraftcardrequest")
          .then(res => {
            this.cardrequests = res.data.data;
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async getUserProfile() {
      try {
        await Api.getRequest(`getuserbyid/${this.user.id}`).then(res => {
        // console.log("user card", res)
          this.safebalance = res.data.safe;
          this.user2 = res.data.user;
          this.fulldata = res.data.fulldata;
          this.userOverdraftCard = res.data.overdraft_card_object;
        });
      } finally {
        // console.log("closed");
      }
    },

    async selectState() {
      await this.getCities();
    },
    async getStates() {
      // const id = this.countryId;
      // this.$store.commit("setApiWarning", "Loading state...");
      try {
        await Api.getRequest(`fetchstatesbycountryid/${161}`)
          .then(res => {
            if (res.data.success) {
              this.states = res.data.states;
              // return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            // console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    async getCities() {
      const id = this.stateid.id;
      try {
        await Api.getRequest(`fetchcitiesbystateid/${id}`)
          .then(res => {
            if (res.data.success) {
              this.cities = res.data.cities;
              // return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            // console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    async fetchTransactionHistory() {
      try {
        await Api.getRequest(
          `fetchoverdrafttransactionshistory/${this.user.id}`
        )
          .then(res => {
            if (res.data.success) {
              // if (res.data.transactions.length > 0) {
              //   // this.historyTransaction = true;
              //   return (this.transactionHistory = res.data.transactions);
              // }
              return (this.transactionHistory = res.data.transactions);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async fetchVendorCount() {
      try {
        await Api.getRequest(`getoverdraftvendorcounter/${this.user.id}`)
          .then(res => {
            if (res.data.success) {
              return (this.vendorCount = res.data);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async fetchAgentCount() {
      try {
        await Api.getRequest(`getoverdraftagentcounter/${this.user.id}`)
          .then(res => {
            if (res.data.success) {
              return (this.agentCount = res.data);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    }
  },
  // computed: {
  //   class() {
  //     return this.maxamountforrange2 === this.detailsProject.maxamountforrange;
  //   }
  // },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    moment2: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$offBlack: #262626;
$limeGreen: #3ec250;
$sheltaGrey: #555c74;

::-webkit-scrollbar {
  display: none;
}

.holder {
  background: $secondary;
  height: 100vh;
  padding: 24px 24px;
}

.approval-request-notification {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ffb100;
  background: $secondary;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  small {
    display: flex;
    color: #555c74;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.approval-header-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    color: #4d4104;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
}

.close-notification {
  // border: 1px solid #555C74;
  color: #555c74;
  padding: 5px 10px;
  font-size: 16px;
  box-shadow: none;
  outline: 0;
}

.view-request {
  margin-top: 8px;
  display: flex;
  border-radius: 4px;
  border: 1px solid #555c74;
  color: #555c74;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;
  box-shadow: none;
  outline: 0;
}

// Transactions details starts
.transactions-holder {
  border-radius: 16px;
  background: #f7f9fb;
  padding: 16px;
  margin-bottom: 30px;
}
.transaction-text {
  color: $sheltaGrey;
  text-align: left;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.tab-buttons {
  text-align: left;
  button {
    color: #2b3352;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-bottom: 1px solid #e2e8f1;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
  }
  .active-tab-btn {
    color: $primary;
    border-bottom: 1px solid $primary;
  }
}

.transaction-items {
  margin-bottom: 10px;
}
.item-holder-t {
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  img {
    margin-right: 12px;
  }
  h4 {
    color: #2b3352;
  }
  h5 {
    color: $sheltaGrey;
  }
  h5,
  h4 {
    text-align: left;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
  }
  h6 {
    color: $sheltaGrey;
    text-align: right;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 0;
  }
}
.item-holder-t > div {
  display: flex;
  text-align: left;
  align-items: flex-start;
}

.approval-header {
  margin-bottom: 24px;
  small,
  h4,
  h6,
  h5 {
    color: #2b3352;
    text-align: center;
    font-family: Lato;
    font-style: normal;
  }
  small {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  h4 {
    font-size: 32px;
    font-weight: 600;
    line-height: 125%;
  }
  h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.transaction-details-text {
  color: #555c74;
  text-align: right;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-align: left;
  margin-bottom: 16px;
}
.approval-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  h5 {
    color: $sheltaGrey;
    text-align: right;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.approval-item-2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  h5 {
    color: $sheltaGrey;
    text-align: right;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
.outstanding-balance {
  color: #2bc300 !important;
}
.balance-after-transaction {
  color: #ea0033 !important;
}

.transaction-pin {
  display: flex;
  justify-content: center;
}
.input-pin {
  margin: 8px;
  width: 80px;
  color: $primary;
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.64px;
  border-radius: 8px;
  border: 2px solid $primary;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
// Transactions details ends

.welcome-back-text {
  color: $sheltaGrey;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}

.regupgrade-btn {
  display: flex;
  margin: 24px 0;
  button {
    // height: 32px;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-radius: 16px;
    box-shadow: none;
    outline: 0;
  }
  button:first-child {
    background: $primary;
    color: $secondary;
    margin-right: 8px;
  }
  button:nth-child(2) {
    background: #eaecef;
    color: $sheltaGrey;
  }
  .request-payment-btn {
    background: $primary;
    color: $secondary;
    margin-right: 8px;
  }
}

.requestcard-btn {
  background: #eaecef;
  color: $sheltaGrey;
}

// card data starts
.card-holder {
  margin-bottom: 40px;
}
.top-card {
  border-radius: 16px;
  padding: 24px;
  min-height: 130px;
  min-width: 250px;

  h6,
  h4 {
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  h5 {
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  h4 {
    border: 1px solid $sheltaGrey;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 8px;
    font-weight: 600;
  }
  p {
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    text-transform: capitalize;
    margin-bottom: 0;
  }
}
.first-card {
  background: #f2f2f2;
}
.second-card {
  background: #e6f2ff;
}
.third-card {
  background: #d6eaf8;
}
.view-btn {
  height: 24px;
  padding: 0 16px;
  border-radius: 4px;
  border: 1px solid $sheltaGrey;
  color: $sheltaGrey;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  // line-height: 150%;
  text-transform: capitalize;
}
// first-card

@media (max-width: 767px) {
  .mobile-card {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  // .col-md-4 {
  //   width: 100% !important;
  //   flex: 0 0 100%;
  //   max-width: 100%;
  // }
}
// card data ends

// Recent Acitivites
.recent-activity-holder {
  border-radius: 16px;
  background: #f7f9fb;
  padding: 16px;
  margin-bottom: 50px;
}
.recent-activity-text {
  color: $sheltaGrey;
  text-align: left;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  margin-bottom: 8px;
  padding: 8px 0;
}

.recent-holder {
  padding: 16px 8px;
}

.rooundedCirclePink {
  padding: 8px;
  background: #f97cda;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  margin-right: 12px;
  display: flex;
}
.rooundedCircleBlue {
  background: #849ad8;
  padding: 8px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  margin-right: 12px;
  display: flex;
}
.activity-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  h6 {
    text-align: left;
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
  }
  p {
    color: #555c74;
    text-align: right;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 0;
  }
}
.view-more {
  p {
    color: $primary;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    text-transform: capitalize;
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
}
// Recent Activities

// Notification starts
.notification {
  border-left: 1px solid rgba(28, 28, 28, 0.1);
  padding: 0px 16px 24px;
  height: 100%;
}
.total-commission-holder {
  h6 {
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  h3 {
    color: #2b3352;
    text-align: left;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 24px;
  }
}

.commission-details {
  margin-bottom: 24px;
  p {
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
}

.notification-section {
  .notification-text {
    margin-bottom: 10px;
    color: #2b3352;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-align: left;
  }

  .notification-item {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 8px 0;
    div {
      h2 {
        color: #2b3352;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: capitalize;
        text-align: left;
        margin-bottom: 0;
        cursor: pointer;
      }
      p {
        color: #ccced5;
        text-align: right;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: capitalize;
        text-align: left;
        margin-bottom: 0;
      }
    }
  }
}
.notification-details-text {
  color: #515054;
  text-align: left;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
// Notifications ends

// Request for payment starts
.label-tags {
  color: $offBlack;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 8px;
}
.loading-card-details {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.input-field,
.select-option {
  height: 48px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  color: #667085;
  outline: 0;
  box-shadow: none;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  // line-height: 150%;
}

.theform {
  margin-bottom: 24px;
}
.form-check-input {
  width: 20px !important;
  height: 20px !important;
}

.header-text {
  padding-left: 15px;
  text-align: left;
  color: $offBlack;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.cancel-btn {
  height: 48px;
  border-radius: 8px;
  background: $secondary;
  border: 1px solid #ddd;
  color: #000a2f;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  outline: 0;
  box-shadow: none;
  width: 100%;
}

.continue-btn {
  border-radius: 6px;
  background: $primary;
  color: $secondary;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  height: 48px;
  outline: 0;
  box-shadow: none;
  width: 100%;
}
.requestamount-notifcation {
  border-radius: 8px;
  background: #f6f6f6;
  padding: 16px;
  p {
    color: $sheltaGrey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
    text-align: left;
    span {
      font-weight: 600;
    }
  }
}
// Request for payment ends

// Upgrade card starts
.upgrade-card {
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  background: $secondary;
  padding: 16px;
  margin-bottom: 12px;
}
.card-upgrade-option {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    margin-right: 12px;
  }
  h5,
  h6 {
    color: $sheltaGrey;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    text-align: left;
  }
  h6 {
    font-weight: 600;
  }
  h5 {
    font-weight: 400;
    span {
      border-radius: 16px;
      background: #d9d9d9;
      padding: 2px 8px;
      color: #2b3352;

      text-align: center;
      font-family: Lato;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-left: 8px;
    }
  }
  i {
    color: $sheltaGrey;
    font-size: 15px;
  }
}
// Upgarde card ends

// Upgrade details starts
.back-chevron {
  margin: 24px 24px 16px;
  text-align: left;
  width: fit-content;
  i {
    background: #f9f8f8;
    border-radius: 30px;
    padding: 9px 10px;
    font-size: 12px;
    cursor: pointer;
  }
}
.top-detail {
  margin-bottom: 24px;
  h5,
  h2,
  h6 {
    font-family: Lato;
    font-style: normal;
    text-align: left;
    color: $sheltaGrey;
    margin-bottom: 4px;
  }
  h5 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 150%;
  }
  h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
.card-details-holder {
  border-radius: 16px;
  background: #f9f8f8;
  padding: 0 16px;
}

.card-details-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  h4 {
    color: $sheltaGrey;
    text-align: right;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
  }
}

.title-modal {
  color: #2b3352;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
}

.payments-buttons {
  margin-bottom: 20px;

  button {
    border: 1px solid #e4e7ec;
    border-radius: 12px;
    height: 64px;
    width: 100%;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
  }
}

.check-click {
  width: 16px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-left: 2px;
  padding-bottom: 2px;

  i {
    font-size: 10px;
    color: $secondary;
  }
}

.activePaymentMethod {
  background: #dde4fc !important;
  border: 1px solid $primary !important;
}

.activeCheckBox {
  background: $primary !important;
  border: 1px solid $primary !important;
}

.payment-select {
  margin-bottom: 40px;
  display: flex;
  img {
    margin-bottom: 8px;
    display: flex;
  }
  button {
    border-radius: 12px;
    border: 1px solid #e4e7ec;
    padding: 12px;
    box-shadow: none;
    outline: 0;
    width: 100%;
    h6 {
      color: $sheltaGrey;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
      text-align: left;
    }
    p {
      color: $sheltaGrey;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
      text-align: left;
    }
    .reuse {
      font-weight: 400;
    }
  }
  button:first-child {
    margin-right: 16px;
  }
}
.active-select-option {
  border: 1px solid $primary !important;
}

.card-upgrade-approval-header {
  margin-bottom: 24px;
  h2,
  h5,
  h4,
  h6 {
    color: #2b3352;
    text-align: center;
    font-family: Lato;
    font-style: normal;
    margin-bottom: 0;
  }
  h5,
  h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 125%;
  }
  h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
  }
}
.card-features-text {
  color: $sheltaGrey;
  text-align: right;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-align: left;
}
// Upgrade details ends

// New card request starts
.delivery-pickup-text {
  text-align: left;
  display: flex;
  font-family: Lato;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 14px;
}
.newcard-option-text {
  text-align: left;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.delivey-address-snip {
  h6 {
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }
}
.form-check-input {
  width: 20px !important;
  height: 20px !important;
}

.guarantor-boby {
  padding: 20px 24px;
}
.agents-holder {
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 2px solid #e4e7ec;
  display: flex;
  padding: 16px;
  small {
    margin-bottom: 0;
    width: 40px;
    height: 40px;
    background: #f0f6ff;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $primary;
    border-radius: 50px;
    padding: 10px;
    margin-right: 10px;
    text-transform: uppercase;
  }
  h5,
  h6,
  h4 {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 4px;
  }
  h5 {
    color: #2b3352;
  }
  h6,
  h4 {
    color: #555c74;
  }
}
.rating {
  text-align: left;
  img {
    cursor: pointer;
    padding-right: 8px;
  }
}
.select-holder {
  width: 16px;
  height: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  i {
    font-size: 10px;
    display: flex;
    padding: 2.4px;
    color: $secondary;
  }
}

.add-guarantor {
  padding: 0 24px;
  p {
    cursor: pointer;
    color: $primary;
    text-align: right;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
}
.gurantor-btn {
  display: flex;
  padding: 32px 24px 24px;
  gap: 10px;
  button {
    width: 100%;
    height: 48px;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 8px;
  }
  .cancel {
    color: #2b3352;
    border: 1px solid #d0d5dd;
  }
  .continue {
    background: $primary;
    color: $secondary;
  }
}

.activeGuarantor {
  border: 2px $primary;
  background: #dde4fc;
}
.activeName {
  background: $primary !important;
  color: $secondary !important;
}
.activeCheck {
  background: $primary;
}

// New card request ends

@media only screen and (max-width: 599px) {
  .input-pin {
    width: 50px;
    font-size: 26px;
  }
  .small-btn {
    height: 40px;
  }
  .welcome-back-text {
    font-size: 22px;
  }
}

#scrollbar-target::-webkit-scrollbar {
  display: none !important;
}

#scrollbar-target::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#scrollbar-target::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
#scrollbar-target::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
#scrollbar-target::-webkit-scrollbar-thumb:hover {
  background: $primary;
}
::-webkit-input-placeholder {
  /* Edge */
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

::placeholder {
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
